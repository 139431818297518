import { createSelector } from 'reselect'
import routerSelector from './router/routerSelector'

export type Params = {
    context: string
    // eslint-disable-next-line camelcase
    dealer_number?: string
    // eslint-disable-next-line camelcase
    salesman_number?: string
    // eslint-disable-next-line camelcase
    carstore_user_id?: string
    // eslint-disable-next-line camelcase
    return_url?: string
    features?: string
    // eslint-disable-next-line camelcase
    vehicle_code?: string
    token?: string
    target?: string
    prString?: string
    audiCode?: string
}

const initRouterParamsSelector = createSelector(
    routerSelector,
    (router): Params => {
        const { search, query } = router.location
        const parsedSearch = new URLSearchParams(search)

        return {
            ...query,
            return_url: parsedSearch.get('return_url'),
        } as Params
    },
)

export default initRouterParamsSelector
